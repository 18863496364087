/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@angular/cdk/overlay-prebuilt.css';
@import '@ng-select/ng-select/themes/default.theme.css';

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/animations/scale.css';

.firebase-emulator-warning {
  animation: fade-out 0.5s 2s ease-out both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-11-2 11:41:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

body:has(app-slide-over) {
  @apply overflow-hidden;
}

.ng2-pdf-viewer-container:has(.page:nth-child(1):last-child) {
  @apply flex justify-center items-center;
}

.ng2-pdf-viewer-container {
  & .canvasWrapper {
    @apply shadow-2xl;
  }
}

.custom-file-upload {
  padding: 6px 12px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  border: none;
}
